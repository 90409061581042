
.visibility-btn {
    font-size: 0.625rem;
    border-radius: 3px;
    padding-left: 6px;
    padding-right: 7px;
    padding-top: 3px;
    padding-bottom: 4px;
}

.header-plugins {
    max-width: 18.3rem;
    width: 100%;
    padding: 2px;
    border-radius: 3.375rem;
}


.ant-dropdown-menu{
    @apply bg-headerDropDown !important;
    width: 200px;
    border: 1px solid;
    @apply border-headerDropDownOutline;
}

.ant-dropdown-menu li {
    @apply text-textColor !important; 
    @apply font-poppins !important;
    
}

.ant-dropdown-menu li:hover {
    @apply bg-headerDropDownHover !important;
}