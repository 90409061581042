.ant-row-no-x-margin {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 16px;
  /* Set the width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: linear-gradient(
    to bottom,
    #d950a1 -21.36%,
    #c94ec2 19.3%,
    #965df7 56.79%,
    #6d7afb 100%
  );
  /* Set the color of the thumb (scrollbar handle) */
  background-clip: content-box;
  border: 5px solid transparent;
  border-radius: 28px !important;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: rgb(209, 209, 209);
  /* Set the color of the track (the area behind the thumb) */
  border-radius: 28px !important;
  background-clip: content-box;
  border: 5px solid transparent;
}

.no-placeholder::placeholder {
  color: transparent;
}

.ant-collapse-item {
  border-radius: 0.5rem !important;
  border: 1px solid transparent !important;
  background: linear-gradient(to right, white, white),
    linear-gradient(to right, #d950a1, #6d7afb) !important;
  background-clip: padding-box, border-box !important;
  background-origin: padding-box, border-box !important;

  /* padding-left: 21px !important; */
  /* padding-bottom: 16px !important; */
}

.ant-collapse-header {
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  /* padding-top: 11px !important;
    padding-bottom: 11px !important;
    padding-left: 0 !important;
    padding-left: 21px !important; */
}

.ant-collapse-content.ant-collapse-content-active,
.ant-collapse-content {
  /* border-top: none !important; */
  max-height: 326px !important;
  /* padding-top: 11px !important; */
  overflow-y: auto;
}

.ant-collapse-content-box {
  padding: 10px !important;
}

.ant-collapse-content.ant-collapse-content-active::-webkit-scrollbar {
  width: 16px;
  /* Set the width of the scrollbar */
}

.ant-collapse-content.ant-collapse-content-active::-webkit-scrollbar-thumb {
  background: linear-gradient(
    to bottom,
    #d950a1 -21.36%,
    #c94ec2 19.3%,
    #965df7 56.79%,
    #6d7afb 100%
  );
  /* Set the color of the thumb (scrollbar handle) */
  background-clip: content-box;
  border: 5px solid transparent;
  border-radius: 28px !important;
}

.ant-collapse-content.ant-collapse-content-active::-webkit-scrollbar-track {
  background-color: rgb(209, 209, 209);
  /* Set the color of the track (the area behind the thumb) */
  border-radius: 28px !important;
  background-clip: content-box;
  border: 5px solid transparent;
}

.ant-collapse-header-text {
  font-size: 14px;
}

.ant-layout .ant-layout-sider-trigger {
  /* top: 5.3rem !important; */
  top: 2.5rem !important;
  height: 35px !important;
  line-height: 35px !important;
  background: transparent !important;
}
.ant-layout .ant-layout-sider {
  background: transparent !important;
}

.ant-divider-horizontal {
  margin: 10px 0 !important;
}

.ant-skeleton-image {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0.75rem;
}
.ant-skeleton.ant-skeleton-element {
  width: 100% !important;
  height: 100% !important;
}
ul {
  list-style: disc;
}


.ant-skeleton{
  margin-left: 4px
}

.ant-skeleton-title, .ant-skeleton-paragraph li {
  @apply bg-loader !important;
}

.ant-skeleton-paragraph {
  width: 90% !important;
}

.ant-skeleton-header span{
  @apply bg-loader !important;
}

.ant-modal-close-x svg{
  @apply text-textColor !important;  
}

.ant-upload-list-item-name {
  @apply text-textColor !important;
}


.ant-modal-content{
  @apply bg-dullBlackBg !important;
  @apply text-white !important;
}

.ant-modal-body p {
  @apply text-textColor !important;   
}

.ant-modal-title{
  @apply text-textColor !important;  
  @apply bg-dullBlackBg !important;
}

.ant-modal-footer button{
  @apply text-textColor !important;  
}

.ant-upload-list-item-container{
  display: flex;
  justify-content: center;
}
.ant-upload-list-item-name{
  font-size: 1rem;
}

.ant-upload-icon > span{
  color: white !important;
}

.anticon.anticon-delete{
  color: red !important;
}


.sc-beySPh.gZmPOF{
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1000 !important;
}

.blur-filter{
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(12px);
  background-color: transparent;
}


/* Progress Bar */
.progress-bar-container{
  margin-left: 8rem;
  z-index: 2000; 
}

.progress-bar {
  position: relative;
  height: 10px;
  width: 500px;
  background: rgb(175, 175, 175);
  border-radius: 25px;
}

.progress-fill {
  position: absolute;
  height: 10px;
  width: 0%;
  /* animation: progress-forward 3s infinite; */
  transition: width 0.5s ease;
  background: black;
  border-radius: 15px;
}

@keyframes progress-forward {
  0% {
    width: 0%;
  }
  25% {
    width: 50%;
  }
  50% {
    width: 75%;
  }
  75% {
    width: 85%;
  }
  100% {
    width: 100%;
  }
} ;
/*  */