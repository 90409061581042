.ant-layout-sider-children{
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;

}

.tcp-logo {
  width: 124px;
  height: 124px;
}


.ant-menu-item.ant-menu-item-active.ant-menu-item-selected {
  @apply text-textColor;
  @apply bg-mainBg;
}

.ant-menu-item.ant-menu-item-selected {
  @apply text-textColor;
  @apply bg-menuItemSelected;
}

.ant-menu-item:hover {
  @apply text-textColor !important;
  @apply bg-menuItemHighlight !important;
}

.ant-layout-sider {
  @apply bg-mainBg;
}


.ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title{ 
  color: white !important;
}
.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected .ant-menu-submenu-title{ 
  color: white !important;
}


