/* Apply the style only when the "remove-counter" class is present */
input.remove-counter[type="number"] {
    -moz-appearance: textfield;
  }
  
  /* Remove the counter buttons for Webkit browsers */
  input.remove-counter::-webkit-outer-spin-button,
  input.remove-counter::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }