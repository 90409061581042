/* .messages-container::-webkit-scrollbar {
    width: 0.0em; 
}
  
.messages-container::-webkit-scrollbar-thumb {
    background-color: #888; 
} */

.peview-rounded > .ant-image > .ant-image-mask {
  border-radius: 12px;
}

.msg-container{
  overflow-y: auto;
}


.source-modal .ant-modal-footer{
  display: none;
}

.download-btn{
  border: 1px dotted white;
}

.download-btn:hover{
  border: 1px solid #3F96FE;
}