.ant-upload.ant-upload-drag{
    /* Uncomment in dark mode */
    /* border: 1px solid; */
    /* @apply border-uploaderOutline ; */
}


.ant-upload.ant-upload-drag:hover{
   
    border: 1px dashed !important; 
    @apply border-uploaderOutline !important; 
}